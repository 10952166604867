const setCookie = (name, value, days) => {
  // client side
  if (typeof window !== 'undefined') {
    const d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`
  }
}

export default setCookie
