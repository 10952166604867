import { getAPI, postAPI, patchAPI } from '../../utils/fetchApiUtils.js'
import { SET_QUOTATION } from '../constants/actionTypes.js'
// import {
//   SET_MEMBER_INFO,
// } from '../constants/actionTypes'

const createQuotation =
  (dispatch) =>
  async (payload = {}) => {
    // const { productId, width, height, count = 0 } = payload
    const res = await postAPI('/quotation', true, payload)

    // 改判斷 https status code？？
    if (res.message === 'Updated') {
      // dispatch({
      //   type: SET_MEMBER_INFO,
      //   payload: { memberInfo: payload, },
      // })
    }
    console.log('res quotaition is: ', res)

    return res
    // res.quotationId
  }

const getQuotation = (dispatch) => async (quotationID) => {
  if (quotationID === undefined) return
  const res = await getAPI(`/quotation/${quotationID}`)

  dispatch({
    type: SET_QUOTATION,
    payload: {
      ...res.data,
    },
  })
}

// FIXME: not completed
const convertQuotationToCart =
  (dispatch) =>
  async (payload = {}) => {
    const res = await postAPI('/quotation', false, payload)

    // 改判斷 https status code？？
    if (res.message === 'Updated') {
      // dispatch({
      //   type: SET_MEMBER_INFO,
      //   payload: { memberInfo: payload, },
      // })
    }

    return res
    // res.quotationId
  }

export { createQuotation, getQuotation, convertQuotationToCart }
