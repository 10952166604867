// category action
export const SET_SELECTED_CATEGORY_ID = 'SET_SELECTED_CATEGORY_ID'

// member action
export const SET_MEMBER_INFO = 'SET_MEMBER_INFO'
export const SET_USER_POINTS_RECORD = 'SET_USER_POINTS_RECORD'
export const SET_USER_PRODUCTS_RECORD = 'SET_USER_PRODUCTS_RECORD'

// product action
export const SET_OPTIONS_PRICE_MAP = 'SET_OPTIONS_PRICE_MAP'
export const SET_ORIGIN_AMOUNT = 'SET_ORIGIN_AMOUNT'

// order action
export const SET_CART_LENGTH = 'SET_CART_LENGTH'
export const SET_CART = 'SET_CART'
export const DELETE_CART_PRODUCT = 'DELETE_CART_PRODUCT'
export const SUBTRACT_CART_LENGTH = 'SUBTRACT_CART_LENGTH'
export const SET_CART_SHIPPING_OPTIONS = 'SET_CART_SHIPPING_OPTIONS'
export const SET_USER_ORDERS = 'SET_USER_ORDERS'
export const SET_USER_QUOTATIONS = 'SET_USER_QUOTATIONS'
export const SET_ORDER_INFO = 'SET_ORDER_INFO'
export const SET_PRODUCT_COMMENTS = 'SET_PRODUCT_COMMENTS'
export const SET_PDF_DETAIL = 'SET_PDF_DETAIL'

// quotation action
export const SET_QUOTATION = 'SET_QUOTATION'
