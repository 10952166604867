import { getAPI, putAPI, patchAPI } from '../../utils/fetchApiUtils.js'
import { SET_MEMBER_INFO, SET_USER_POINTS_RECORD, SET_USER_PRODUCTS_RECORD } from '../constants/actionTypes'

const getAndSetMemberInfo = (dispatch) => async () => {
  const { data: memberInfo } = await getAPI('/user', true)

  dispatch({
    type: SET_MEMBER_INFO,
    payload: { memberInfo: memberInfo || {} },
  })
}

const updateMemberInfo =
  (dispatch) =>
  async (payload = {}) => {
    // const res = await putAPI('/user', true, payload)
    const res = await patchAPI('/user', true, payload)

    if (res.message === 'Updated') {
      dispatch({
        type: SET_MEMBER_INFO,
        payload: { memberInfo: payload },
      })
    }

    return res
  }

const getUserPointsRecord = (dispatch) => async () => {
  const res = await getAPI('/user/pointRecords', true)

  dispatch({
    type: SET_USER_POINTS_RECORD,
    payload: res.data,
  })
}

const getUserProductsRecord =
  (dispatch) =>
  async (page = 1) => {
    const res = await getAPI(`/user/products?page=${page}`, true)

    dispatch({
      type: SET_USER_PRODUCTS_RECORD,
      payload: res.data,
    })
  }

export { getAndSetMemberInfo, updateMemberInfo, getUserPointsRecord, getUserProductsRecord }
