import axios from 'axios'

const convertRes = (res) => {
  const contentType = res.headers.get('Content-Type')
  let resParse
  if (contentType != null) {
    if (contentType.indexOf('text') > -1) {
      resParse = res.text()
    }
    if (contentType.indexOf('form') > -1) {
      resParse = res.formData()
    }
    if (contentType.indexOf('video') > -1) {
      resParse = res.blob()
    }
    if (contentType.indexOf('json') > -1) {
      resParse = res.json()
    }
  }
  return resParse
}

const getAPI = async (path, shouldUseJwe = false, searchParams) => {
  let statusCode
  console.log('getProductFilename payload in getAPI is: ', searchParams)
  // const host = process.server ? req.headers.host : window.location.host

  const prefix = typeof window !== 'undefined' ? '' : process.env.NEXT_PUBLIC_URL
  // const url = `https://${host}`
  console.log('prefix is: ', prefix)

  // return `https://${host}`

  // return await fetch(`${process.env.NEXT_PUBLIC_URL}/api/gateway`, {
  // return await fetch('/api/gateway', {
  return await fetch(`${prefix}/api/gateway`, {
    method: 'POST',
    body: JSON.stringify({
      path,
      method: 'GET',
      shouldUseJwe,
      searchParams,
    }),
  })
    .then((res) => {
      statusCode = res.status
      return res
    })
    .then(convertRes)
    .then((res) => ({
      ...res,
      statusCode,
    }))
    .catch((err) => ({ message: err.message }))
}

const postFormDataAPI = async (path, form) => {
  // return axios.post("/api/gateway", { da: form })
  return axios
    .post('/api/gateway', form)
    .then((res) => {
      console.log(res)
      // res.data.pathKey
    })
    .catch((err) => {
      console.log('err is: ', err)
    })

  // return await fetch('/api/gateway', {
  //   method: 'POST',
  //   body: JSON.stringify({
  //     path,
  //     method: 'POST',
  //     shouldUseJwe,
  //     payload,
  //   })
  // })
  //   .then(convertRes)
  //   .catch(err => ({ message: err.message }))
}

const postAPI = async (path, shouldUseJwe = false, payload = {}) => {
  let statusCode
  console.log('process.env.NEXT_PUBLIC_URL is: ', process.env.NEXT_PUBLIC_URL)
  const prefix = typeof window !== 'undefined' ? '' : process.env.NEXT_PUBLIC_URL
  return await fetch(`${prefix}/api/gateway`, {
    method: 'POST',
    body: JSON.stringify({
      path,
      method: 'POST',
      shouldUseJwe,
      payload,
    }),
  })
    .then((res) => {
      statusCode = res.status
      return res
    })
    .then(convertRes)
    .then((res) => ({
      ...res,
      statusCode,
    }))
    .catch((err) => ({ message: err.message }))
}
const patchAPI = async (path, shouldUseJwe = false, payload = {}) => {
  let statusCode
  console.log('process.env.NEXT_PUBLIC_URL is: ', process.env.NEXT_PUBLIC_URL)
  const prefix = typeof window !== 'undefined' ? '' : process.env.NEXT_PUBLIC_URL
  return await fetch(`${prefix}/api/gateway`, {
    method: 'POST',
    body: JSON.stringify({
      path,
      method: 'PATCH',
      shouldUseJwe,
      payload,
    }),
  })
    .then((res) => {
      statusCode = res.status
      return res
    })
    .then(convertRes)
    .then((res) => ({
      ...res,
      statusCode,
    }))
    .catch((err) => ({ message: err.message }))
}
const putAPI = async (path, shouldUseJwe = false, payload = {}, searchParams = null) => {
  const prefix = typeof window !== 'undefined' ? '' : process.env.NEXT_PUBLIC_URL
  let url = `${prefix}/api/gateway`

  if (searchParams) {
    url += '?' + new URLSearchParams(searchParams).toString()
  }

  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      path,
      method: 'PUT',
      shouldUseJwe,
      payload,
    }),
  })
    .then(convertRes)
    .catch((err) => ({ message: err.message }))
}

const deleteAPI = async (path, shouldUseJwe = false, payload = {}, searchParams = null) => {
  const prefix = typeof window !== 'undefined' ? '' : process.env.NEXT_PUBLIC_URL
  let url = `${prefix}/api/gateway`

  if (searchParams) {
    url += '?' + new URLSearchParams(searchParams).toString()
  }

  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      path,
      method: 'DELETE',
      shouldUseJwe,
      payload,
    }),
  })
    .then(convertRes)
    .catch((err) => ({ message: err.message }))
}

export { getAPI, postFormDataAPI, postAPI, putAPI, patchAPI, deleteAPI }
