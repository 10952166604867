import { getAPI, postFormDataAPI, postAPI } from '../../utils/fetchApiUtils.js'
import { SET_OPTIONS_PRICE_MAP, SET_ORIGIN_AMOUNT } from '../constants/actionTypes'

// size 變 -> 更新 price
// product count 變 -> get 是否秀 usageError
// custom product count 變 -> get custom product count info(including usageError)
const getCountPriceInfo = (dispatch) => async (payload) => {
  const { productId, width, height, sizeAttributeName, count = 0 } = payload

  const countPriceInfo = await getAPI('/product/pricing', false, { ...payload }).then((res) => {
    console.log('res in countPriceInfo is: ', payload, res)
    return res.data
  })

  if (!count) {
    const list = Object.values(countPriceInfo.price).map((e, index) => ({
      id: Date.now() + index,
      value: +Object.keys(countPriceInfo.price)[index],
      singleProductCountAmount: e.price,
      discountPercentValue: e.discountPercent,
      isUsageTooLess: e.lowPercentagePrintingAlert,
    }))
    return list
  }

  return {
    isUsageTooLess: countPriceInfo.customCount.lowPercentagePrintingAlert,
    singleProductCountAmount: countPriceInfo.customCount.price,
    discountPercentValue: countPriceInfo.customCount.discountPercent,
  }
}

// FIXME: unused
const uploadFile = (dispatch) => async (form) => {
  // Content-Type:multipart/form-data
  // const data = await postAPI('/product/upload', false, payload)
  //   .then(res => {
  //     console.log('res is: ', res)
  //     console.log('res is: ', res.pathKey)
  //     return res
  //   })
  //   .catch(err => console.log('err is: ', err))
  const data = await postFormDataAPI('/product/upload', form)
    .then((res) => {
      console.log('res is: ', res)
      console.log('res is: ', res.pathKey)
      return res
    })
    .catch((err) => console.log('err is: ', err))

  // axios.post("http://localhost:7777/product/upload", form)
  //   .then(res => {
  //     console.log(res)
  //     // res.data.pathKey
  //   })
  //   .catch(err => {
  //     console.log('err is: ', err)
  //   })
  // console.log('data is: ', data)
}

const resetOptionsPriceMap = (dispatch) => () => {
  dispatch({
    type: SET_OPTIONS_PRICE_MAP,
    payload: { optionsPriceMap: {} },
  })
}

const getOptionsPriceMap = (dispatch) => async (payload) => {
  const optionsPriceMap = await getAPI('/product/addition/pricing', false, payload).then((res) => {
    console.log('res in optionsPriceMap is: ', res)
    return res.data
  })

  console.log('optionsPriceMap is: ', optionsPriceMap)

  dispatch({
    type: SET_OPTIONS_PRICE_MAP,
    payload: { optionsPriceMap },
  })
}

const addProductToCart =
  (dispatch) =>
  async ({ isLogin, ...payload }) => {
    const res = await postAPI('/cart/addProduct', isLogin, payload).then((res) => {
      console.log('res in addProductToCart is: ', res)
      return res
    })

    console.log('res is: ', res)

    if (res.statusCode === 201) {
      // dispatch({
      //   type: ADD_CART_LENGTH,
      //   // payload: ,
      // });
    }

    // dispatch({
    //   type: SET_OPTIONS_PRICE_MAP,
    //   payload: { optionsPriceMap },
    // });
    return res
  }

export { getCountPriceInfo, getOptionsPriceMap, resetOptionsPriceMap, uploadFile, addProductToCart }
